import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { Box, Typography, Container, Grid, Button } from "@mui/material";
import ErrorOverlaymodal from "../new-design/ErrorOverlayModal";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "react-toastify/dist/ReactToastify.css";
import ZJoule from "../../assets/images/zjoule.png";

import AuthContext from "../../contexts/AuthContext";
import useSession from "../../hooks/useSession";
import { reactAppConfig } from "../../config/react-app-config";
import { AppBar } from "@material-ui/core";
import Logo from "../../assets/images/logo.svg";


const schemaPass = yup
  .object({
    username: yup.string().email().required("Please Enter Email"),
    password: yup.string().required("Please Enter Password"),
  })
  .required();

export default function LoginScreen() {
  const navigate = useNavigate();
  const {
    register: registerPass,
    handleSubmit: handlePass,
    clearErrors,
    reset,
    formState: { errors: errorPass },
  } = useForm({
    resolver: yupResolver(schemaPass),
  });

  const { setIsLoggedIn } = useContext(AuthContext);
  const { login } = useSession();
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(<VisibilityOff className="icons-color" />);
  const [showLogin, setShowLogin] = useState(false);

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  

  const handleToggle = () => {
    if (type === "password") {
      setIcon(<Visibility className="icons-color" />);
      setType("text");
    } else {
      setIcon(<VisibilityOff className="icons-color" />);
      setType("password");
    }
  };

  const handleLogin = async (detail) => {
    setPosition(200);
    setShowLogin(true);
    let data = new FormData();
    data.append("password", detail.password);
    data.append("username", detail.username);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${reactAppConfig.baseUrl}/api/auth/login`,
      headers: {
        accept: "application/json",
      },
      data: data,
    };

    // await axios
    //   .request(config)
    //   .then((response) => {
      try{
      const response  = await axios.request(config);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("identifier", response.data.identifier);

        localStorage.setItem("userEmail", detail.username);
        setIsLoggedIn(true);
        navigate("/");
        login(response.data.accessToken);
        reset({ username: "", password: "" });
      }catch (error) {
          if (error?.message === "Network Error"  && error?.code === "ERR_NETWORK") {
            setError(error);
            setOpen(true);
          } else  {
            toast.error("Please enter correct username and password", {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        reset({ username: "", password: "" });
      }
      } finally {
        setShowLogin(false);
      } 
  };

  const [position, setPosition] = useState(0);

  useEffect(() => {
    const scrollIcon = document.getElementById("scrollIconLogin");
    if (scrollIcon && position >= 170) {
      scrollIcon.style.display = "none";
    }
  }, [position]);

  function scrollToMainContainer() {
    const mainContainer = document.getElementById("logincontainer");
    mainContainer.scrollIntoView({ behavior: "smooth" });
  }

  window.addEventListener("scroll", function () {
    const scrollIconLogin = document.getElementById("scrollIconLogin");

    const scrollPositionLogin = window.scrollY;
    if (scrollIconLogin) {
      if (scrollPositionLogin > 170) {
        setPosition(scrollPositionLogin);
        scrollIconLogin.style.display = "none";
      } else {
        scrollIconLogin.style.display = "block";
      }
    }
  });

  return (
    <Box>
        <AppBar className="relative bg-white py-2 login px-0" >
          <Container maxWidth="xl" className="relative">
            <Box className="flex justify-between items-center h-[100%]">
              <Box
                className="flex"
              >
                <Link to="">
                  <img src={Logo} alt="" />
                </Link>
              </Box>
              <Box
                className="z-joule-container mb-0"
                style={{ fontFamily: "Nunito Sans", textAlign: "center" }}
              >
                <p style={{ display: "inline-block", verticalAlign: "middle" }}>
                  Powered by
                </p>
                <span
                  style={{
                    display: "inline-block",
                  }}
                >
                  <Link to="https://z-joule.com/" target="_blank">
                    <img
                      src={ZJoule}
                      alt=""
                      style={{ verticalAlign: "middle" }}
                    />
                  </Link>
                </span>
              </Box>

              <Box
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px'}}
              >
             {/*   <NavLink to="/faqs" target="_blank">
                  <button
                    className="faq-button text-cyan-blue font-normal"
                    type="button"
                    style={{
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    FAQs
                  </button>
                </NavLink> */}
              </Box>
            </Box>
          </Container>
        </AppBar>
        <Box className="t-0 w-full h-full object-cover">
          <div
            id="scrollIconLogin"
            className="box"
            onClick={() => {
              scrollToMainContainer();
            }}
          >
          </div>

          <img
            src={
              "https://storage.googleapis.com/vessel-images/sf2_splash_screen_login.png"
            }
            alt="ship"
            className="w-full login-bg object-cover"
            style={{
              height:"-webkit-fill-available"
            }}
          />
        </Box>
        <div className="items-center login-box w-full">
          <Grid
            id="logincontainer"
            container
            justifyContent="center"
            alignItems="center"
            className="login-container"
            sx={{
              width: "28rem",
              padding: "2rem",
            }}
          >
            <h4 className="login-heading" style={{ fontFamily: "Nunito Sans" }}>
              Sign In
              <br />
            </h4>

            <form className="w-auto" onSubmit={handlePass(handleLogin)}>
              <Grid
                className="w-full flex justify-center mt-4 mb-0"
                style={{ lineHeight: "0rem" }}
              >
                <input
                  type="text"
                  name="username"
                  placeholder="Enter Your Email"
                  className="input-mail-id relative"
                  {...registerPass("username")}
                  onBlur={(e) => {
                    if (
                      e.target.value === null ||
                      e.target.value.trim() === ""
                    ) {
                      clearErrors("username");
                    }
                  }}
                />
              </Grid>
              {errorPass.username && (
                <Grid className="w-full flex justify-center">
                  <Typography className="text-error text-base">
                    {errorPass.username?.message}
                  </Typography>
                </Grid>
              )}
              <Grid className="w-full flex justify-center mt-4 mb-0">
                <input
                  type={type}
                  name="password"
                  placeholder="Enter Your Password"
                  className="input-mail-id relative"
                  {...registerPass("password")}
                  onBlur={(e) => {
                    if (
                      e.target.value === null ||
                      e.target.value.trim() === ""
                    ) {
                      clearErrors("password");
                    }
                  }}
                />
                <span
                  className="flex justify-around items-center"
                  onClick={handleToggle}
                >
                  <div className="position mr-12">{icon}</div>
                </span>
              </Grid>
              {errorPass.password && (
                <Grid className="w-full flex justify-center">
                  <Typography className="text-error text-base">
                    {errorPass.password?.message}
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                className="mt-[10px] mx-auto w-full flex justify-center"
              >
                <Button
                  className={
                    "!py-[10px] w-full rounded-[4px] module-border-wrap text-base leading-5 text-white font-medium"
                  }
                  type="submit"
                  disabled={showLogin}
                  style={{
                    // "width": "300px"
                    lineHeight: "1rem",
                  }}
                >
                  LOG IN
                  {showLogin && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-5 h-5 ms-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </Button>
              </Grid>
              <Grid item className="w-full flex justify-center mb-[10px]">
                <Link
                  className="text-white font-normal text-base mt-[10px] text-underline"
                  style={{ fontFamily: "Nunito Sans" }}
                  to="/forgotpassword"
                  target="_blank"
                >
                  {" "}
                  Reset Password{" "}
                </Link>
              </Grid>
              <Grid
                item
                className="w-full flex justify-center"
                style={{ lineHeight: "1rem" }}
              >
                <p
                  className="text-white fs-14"
                  style={{ fontFamily: "Nunito Sans" }}
                >
                  Please{" "}
                  <Link
                    className="text-cyan-blue font-normal text-base mt-[10px] text-underline"
                    to="mailto:contact@z-joule.com"
                  >
                    contact us
                  </Link>{" "}
                  if you need assistance to log in. 
                </p>
              </Grid>
            </form>
          </Grid>
          <ErrorOverlaymodal
        open={open}
        handleClose={handleClose}
        errorMessage="The system is temporarily out of service. Please contact us if this error persists."
        isLoginScreen={true}
     />
        </div>
      </Box>
  );
}
