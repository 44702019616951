import {
  Divider,
  Grid,
  Typography,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import pattern from "patternomaly";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef, useState, useContext } from "react";
import { chartContext } from "../../common/ChartContex";
import { patternsObj } from "../../common/formatter";

import Tooltip from "@mui/material/Tooltip";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

Chart.register(ChartDataLabels);
export default function EUETSCostsChart({
  customClass,
  textBlack,
  compliance_myEUETSCostsOptions,
  coc_chartOption,
  show,
  textColor,
  block,
}) {
  const chartData = useContext(chartContext);
  useEffect(() => { }, [chartData]);

  const [keyCompliance, setKeyCompliance] = useState([]);
  const [valueCompliance, setValueCompliance] = useState([]);
  const [showChart, setShowChart] = useState(false);

  const [headerData, setHeaderData] = useState();
  const [fuelData, setFuelData] = useState();

  const [keyComplianceEuChart, setKeyComplianceEuChart] = useState([]);
  const [valueComplianceEuChart, setValueComplianceEuChart] = useState([]);

  const [allData, setAllData] = useState([]);

  const [tableData, setTableData] = useState();

  const myCocChart = chartData?.myEUETSCosts;
  const coc_chart = chartData?.myCOCchart;

  useEffect(() => {
    if (chartData?.myCOCchart) {
      // // ====================================================
      // // 1. EUTS cost chart (hidden)
      // const myCocChart = chartData?.myEUETSCosts;

      // if (chartData?.myEUETSCosts !== null) {
      //   setShowChart(false); // deactivated
      //   const allowanceCost_new =
      //     myCocChart?.allowanceCost_v2;

      //   const yearEu = allowanceCost_new[0].points.map((y) => y.year);

      //   const chartDataEU = allowanceCost_new.map((item) => ({
      //     label: item.vesselName,
      //     backgroundColor: item.colorHex,
      //     data: item.points.map((point) => point.value.toFixed(2)),
      //   }));

      //   setKeyCompliance(yearEu);
      //   setValueCompliance(chartDataEU);
      // }

      // ====================================================
      // 2. The 5-stacked CoCC chart
      // TODO: We should be able to turn entire columns on/off, just as we do with individual entries in the legend.
      // TODO: The legend of this chart is not capable of managing patterns, only colors. See the Fuel cost chart, which handles patterns. 
      const coc_chart = chartData?.myCOCchart;
      const myCOCChart = coc_chart?.myCOCchart;
      const newLegends = coc_chart?.newLegends;

      setTableData(newLegends);

      const fuelsData = Object.keys(newLegends);

      setFuelData(fuelsData);
      setHeaderData(coc_chart?.legendHeadings);

      const shipNamesEuChart = Object.keys(myCOCChart);

      const labels = Object.keys(myCOCChart[shipNamesEuChart[0]]);

      const yearsEuChart = Object.keys(myCOCChart[shipNamesEuChart[0]]);

      const datasetsEuChart = [];

      const propulsionTypes = Array.from(new Set(Object.keys(myCOCChart)));
      propulsionTypes.forEach((propulsionSelection, index) => {
        const propulsionCosts = [];
        const complianceCosts = [];
        const nonComplianceCredits = [];
        const etsallowances = [];
        const IMOlevy = [];

        const propulsionLegendInfoNew = newLegends[propulsionSelection]?.propulsion || {};
        const complianceLegendInfoNew = newLegends[propulsionSelection]?.["bio/synth premia"] || {};
        const nonComplianceCreditsInfoNew = newLegends[propulsionSelection]?.["Intensity penalties (IMO+EU)"] || {};
        const etsallowancesInfoNew = newLegends[propulsionSelection]?.["ETS costs"] || {};
        const IMOlevyInfoNew = newLegends[propulsionSelection]?.["IMO levy"] || {};

        // This is where the data is being fetched from the JSON object.
        // See class CoCEntry -- the labels must largely match (excpet case)
        labels.forEach((year) => {
          propulsionCosts.push(myCOCChart[propulsionSelection][year]["propulsionCost"]);
          complianceCosts.push(myCOCChart[propulsionSelection][year]["complianceCost"]);
          nonComplianceCredits.push(myCOCChart[propulsionSelection][year]["totalIntensityPenalty"]);
          etsallowances.push(myCOCChart[propulsionSelection][year]["etsallowances"]);
          IMOlevy.push(myCOCChart[propulsionSelection][year]["imolevies"]);
        });

        datasetsEuChart.push(
          {
            label: `${propulsionSelection} propulsion`,
            type: "bar",
            stack: propulsionSelection,
            data: propulsionCosts,
            index: index,
            stackIndex: 1,
            backgroundColor:
              propulsionLegendInfoNew?.pattern === "solid" ?
                propulsionLegendInfoNew?.myColor :
                pattern.draw(patternsObj[propulsionLegendInfoNew?.pattern], propulsionLegendInfoNew?.myColor)
          },
          {
            label: `${propulsionSelection} bio/synth premia`,
            type: "bar",
            stack: propulsionSelection,
            data: complianceCosts,
            index: index,
            stackIndex: 2,
            backgroundColor:
              complianceLegendInfoNew?.pattern === "solid" ?
                complianceLegendInfoNew?.myColor :
                pattern.draw(patternsObj[complianceLegendInfoNew?.pattern], complianceLegendInfoNew?.myColor)
          },
          {
            label: `${propulsionSelection} ETS costs`,
            type: "bar",
            stack: propulsionSelection,
            data: etsallowances,
            index: index,
            stackIndex: 3,
            backgroundColor:
              etsallowancesInfoNew?.pattern === "solid" ?
                etsallowancesInfoNew?.myColor :
                pattern.draw(patternsObj[etsallowancesInfoNew?.pattern], etsallowancesInfoNew?.myColor)
          },
          {
            label: `${propulsionSelection} IMO levy`,
            type: "bar",
            stack: propulsionSelection,
            data: IMOlevy,
            index: index,
            stackIndex: 4,
            backgroundColor:
              IMOlevyInfoNew?.pattern === "solid" ?
                IMOlevyInfoNew?.myColor :
                pattern.draw(patternsObj[IMOlevyInfoNew?.pattern], IMOlevyInfoNew?.myColor)
          },
          {
            label: `${propulsionSelection} Intensity penalties (IMO+EU)`,
            type: "bar",
            stack: propulsionSelection,
            data: nonComplianceCredits,
            index: index,
            stackIndex: 5,
            backgroundColor:
              nonComplianceCreditsInfoNew?.pattern === "solid" ?
                nonComplianceCreditsInfoNew?.myColor :
                pattern.draw(patternsObj[nonComplianceCreditsInfoNew?.pattern], nonComplianceCreditsInfoNew?.myColor)
          }
        );
      });

      setKeyComplianceEuChart(yearsEuChart);
      setValueComplianceEuChart(datasetsEuChart);
      setAllData(datasetsEuChart);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  const [open1, setOpen1] = useState(false);
  const [title1, setTitle1] = useState("Chart Information");
  const [data1, setData1] = useState(null);

  const handleCoCChart = (title) => {
    setTitle1(title);
    setData1(coc_chart.comments);
    setOpen1(!open1);
  };

  useEffect(() => { }, [title]);

  const handleChartData = (e, data) => {
    const dataExists = valueComplianceEuChart.some(
      (dataPoint) => dataPoint.label === data
    );

    if (dataExists) {
      e.classList.remove('active');
      const filteredValueComplianceChart = valueComplianceEuChart.filter(
        (item) => item.label !== data
      );
      setValueComplianceEuChart(filteredValueComplianceChart);
    } else {
      e.classList.add('active');
      const prevData = allData.filter((item) => item.label === data);

      const updatedChart = [...valueComplianceEuChart, ...prevData];

      setValueComplianceEuChart(updatedChart);
    }
  };

  const filteredValueComplianceEuChart = valueComplianceEuChart.map(
    (dataset) => {
      const filteredData = dataset.data.map((value) => Math.max(value, 0)); // Ensure only non-negative values
      return { ...dataset, data: filteredData };
    }
  );
  filteredValueComplianceEuChart.sort((a, b) => {
    if (a.index === b.index) {
      return a.stackIndex - b.stackIndex;
    }
    return a.index - b.index;
  });

  const handleClose = () => {
    setOpen(false);
    euEftChart?.current?.scrollIntoView({ behavior: "auto" });
  };
  const handleClose2 = () => {
    setOpen1(false);
    euEtsChart2?.current?.scrollIntoView({ behavior: "auto" });
  };

  const euEftChart = useRef(null);
  const euEtsChartInfoRef = useRef(null);
  const euEtsChart2 = useRef(null);
  const euEtsInfoChart2 = useRef(null);

  useEffect(() => {
    if (open) {
      euEtsChartInfoRef?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  useEffect(() => {
    if (open1) {
      euEtsInfoChart2?.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [open1]);

  return (

    <Grid
      ref={euEtsChart2}
      container
      className={`px-4 pt-7 pb-[24.57px] mt-[30px] rounded-2xl bg-new-text ${customClass}`}
    >
      <Box className="flex justify-between w-full">
        <Box>
          <Typography
            variant="subtitle1"
            className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
          >
            {coc_chart?.chartTitle}
          </Typography>
          <Typography
            variant="subtitle2"
            className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
          >
            {coc_chart?.chartSubTitle}
          </Typography>

          <Typography
            variant="subtitle2"
            className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
          >
            {coc_chart?.scenarioSubtitle}
          </Typography>


        </Box>
        {coc_chart?.comments && show && (
          <Box>
            <Tooltip title="Notes and Sources" arrow>
              <Button
                type="submit"
                onClick={() => {
                  handleCoCChart(coc_chart?.chartTitle);
                }}
              >
                <img src={open1 ? UPImg : BottomImg} alt="Information" />
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Divider className="w-full border border-solid border-mortar-grey my-4" />
      {chartData ? (
        <>
          <Box className="w-full h-[600px]">
            <Bar
              data={{
                labels: keyComplianceEuChart,
                datasets: filteredValueComplianceEuChart,
              }}
              options={coc_chartOption}
            />
          </Box>
          <Grid className={`flex justify-center w-full ${block}`}>
            <Box className="flex justify-center">
              {
                <Table
                  aria-label="simple table"
                  style={{
                    width: "85%",
                    borderCollapse: "separate",
                    borderSpacing: "2rem 1rem",
                  }}
                >
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className="border-0"></TableCell>

                        {headerData?.map((headerItem, index) => (
                          <TableCell
                            key={index}
                            className={`font-medium text-title text-[13px] leading-[15px] text-center  border-0 ${textColor}`}
                          >
                            {headerItem}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {fuelData?.map((fuelItems, index) => (
                        <TableRow
                          key={fuelItems}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component=""
                            scope="row"
                            className={`font-medium text-title text-[12px] leading-[13px] border-0   ${textColor} w-[16rem] text-left p-0`}
                          >
                            {fuelItems}
                          </TableCell>

                          {headerData?.map((item, index) => (
                            <TableCell
                              key={index}
                              className={`font-bold text-[13px] leading-[13px] border-0  text-center cursor-pointer MuiTableCell-sizeSmall; p-0`}
                            >
                              <div className="customLegendBorder active"
                                onClick={(e) => {
                                  const newData = fuelItems + " " + item;
                                  handleChartData(e.target, newData);
                                }}>
                                <div
                                  style={{
                                    width: "44px",
                                    height: "12px",
                                    margin: "auto",
                                    backgroundColor: `${tableData[fuelItems][item]?.myColor
                                      ? tableData[fuelItems][item]?.myColor
                                      : ""
                                      }`,
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const newData = fuelItems + " " + item;
                                    handleChartData(e.target.parentElement, newData);
                                  }}
                                ></div>
                              </div>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                </Table>
              }
            </Box>
          </Grid>
        </>
      ) : (
        "feiching"
      )}
      {open1 && (
        <div ref={euEtsInfoChart2}>
          <Divider className="w-full border border-solid border-mortar-grey my-4" />
          <div className="log-outmodal info">
            <div className="info-header">
              <Grid className="flex justify-start items-start ">
                <Typography
                  variant="h3"
                  className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                >
                  {title1}
                </Typography>
              </Grid>

              <Button onClick={handleClose2}>
                <img src={CloseImg} alt="close" />
              </Button>
            </div>
            {data1 && (
              <div
                className="text-base mt-[10px] info-text text-white"
                dangerouslySetInnerHTML={{ __html: data1 }}
              />
            )}
          </div>
        </div>
      )}
    </Grid>

  );
}
