// Combined FEUM/IMO-MTM Chart
// ===========================

import React, { useContext, useEffect, useRef, useState } from "react";
import { Divider, Grid, Typography, Box, Button, Tooltip } from "@mui/material";
import { Line } from "react-chartjs-2";
import { chartContext } from "../../common/ChartContex";
import { createArrayBetween } from "../../common/formatter";

import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

import BorrowedIcon from "../../assets/images/reshot-icon-chevron-arrow-backward-circle-4CQNS58E3L.svg";
import BankedIcon from "../../assets/images/reshot-icon-chevron-arrow-forward-circle-HL73ANZSEF.svg";
import PenaltyIcon from "../../assets/images/reshot-icon-chevron-arrow-downward-circle-FAVPWX7LSE.svg";
import multipleActions from "../../assets/images/reshot-icon-chevron-circle-3YSZW6UHAN.svg";

export default function CombinedChart({
  customClass,
  optionsFEUM,
  textBlack,
  show,
}) {
  const chartData = useContext(chartContext);

  // 1. Read FEUM and IMO-MTM charts from the context
  const feum_JSON = chartData?.myFEUMchart;
  const mtm_JSON = chartData?.my_MTM_chart;

  // 2. Determine which charts are available
  const hasFEUM = Boolean(feum_JSON);
  const hasMTM = Boolean(mtm_JSON);

  // 3. Manage the user’s selection state
  //    Possible values: "FEUM" or "IMO" (or "none" if neither is available).
  //    If only FEUM is available, default to "FEUM" and hide toggle.
  //    If only IMO is available, default to "IMO" and hide toggle.
  //    If both are available, user can toggle between them.
  let defaultChart = "none";
  if (hasFEUM && hasMTM) defaultChart = "IMO"; // or whichever you prefer as default
  else if (hasFEUM) defaultChart = "FEUM";
  else if (hasMTM) defaultChart = "IMO";

  const [selectedChart, setSelectedChart] = useState(defaultChart);

  // 4. Refs and states for “notes & sources” panel
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [comments, setComments] = useState(null);

  const chartRef = useRef(null);
  const chartInfoRef = useRef(null);
  const iconsRef = useRef({});

  useEffect(() => {
    if (open && chartInfoRef.current) {
      chartInfoRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  // 5. State to track icons loading
  const [iconsLoaded, setIconsLoaded] = useState(false);

  // 6. Pre-load icons programmatically
  useEffect(() => {
    let loadedCount = 0;
    const totalIcons = 4;
    const handleImageLoad = () => {
      loadedCount++;
      if (loadedCount === totalIcons) {
        setIconsLoaded(true);
      }
    };

    const borrowedIconImg = new Image();
    borrowedIconImg.src = BorrowedIcon;
    borrowedIconImg.onload = handleImageLoad;

    const bankedIconImg = new Image();
    bankedIconImg.src = BankedIcon;
    bankedIconImg.onload = handleImageLoad;

    const penaltyIconImg = new Image();
    penaltyIconImg.src = PenaltyIcon;
    penaltyIconImg.onload = handleImageLoad;

    const multipleActionsImg = new Image();
    multipleActionsImg.src = multipleActions;
    multipleActionsImg.onload = handleImageLoad;

    iconsRef.current = {
      borrowedIconImg,
      bankedIconImg,
      penaltyIconImg,
      multipleActionsImg,
    };
  }, []); // Run once on mount

  // Conditionally render only after icons are loaded
  if (!iconsLoaded) {
    return <div>Loading icons...</div>;
  }

  // 7. Helper: Determine icon for FEUM data
  const determineIcon = (element) => {
    const {
      borrowedIconImg,
      bankedIconImg,
      penaltyIconImg,
      multipleActionsImg,
    } = iconsRef.current;

    const isBorrowed = element?.borrowedFromNextYear_gco2eq > 1000;
    const isBanked = element?.bankedToNextYear_gco2eq > 1000;
    const hasPenalty = element?.externalCredits_gCO2eq_basis365 > 0;

    if ((isBorrowed && isBanked) || (isBorrowed && hasPenalty) || (isBanked && hasPenalty)) {
      return multipleActionsImg;
    }
    if (isBorrowed) {
      return borrowedIconImg;
    }
    if (isBanked) {
      return bankedIconImg;
    }
    if (hasPenalty) {
      return penaltyIconImg;
    }
    return "circle";
  };

  // 8. Build FEUM chart data
  const buildFeumDatasets = () => {
    if (!feum_JSON) return { datasets: [], labels: [] };

    const feumDetails = feum_JSON.feum_details || [];
    const limitDetails = feum_JSON.eufmintensityLimit || {};
    const complianceYears = createArrayBetween(
      feum_JSON.firstYear,
      feum_JSON.lastYear
    );

    // Build Vessel lines
    const vesselLines = feumDetails.map((entry) => {
      const { vesselName, colorHex, pointsByYear } = entry;
      const sortedYearData = Object.entries(pointsByYear)
        .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
        .map(([year, feumElement]) => {
          const icon = determineIcon(feumElement);
          return {
            x: +year,
            y: +Number(feumElement.postHelpIntensity_gCo2eq_MJ || 0).toFixed(2),
            vesselName,
            postHelpIntensity_gCo2eq_MJ: feumElement.postHelpIntensity_gCo2eq_MJ || 0,
            borrowedFromNextYear_gco2eq: feumElement.borrowedFromNextYear_gco2eq || 0,
            bankedToNextYear_gco2eq: feumElement.bankedToNextYear_gco2eq || 0,
            unaidedIntensity_gCo2eq_MJ: feumElement.unaidedIntensity_gCo2eq_MJ || 0,
            externalCredits_gCO2eq_basis365: feumElement.externalCredits_gCO2eq_basis365 || 0,
            icon,
          };
        });

      return {
        type: "line",
        label: vesselName,
        data: sortedYearData,
        backgroundColor: colorHex,
        borderColor: colorHex,
        borderWidth: 3,
        lineTension: 0.05,
        pointRadius: (context) =>
          context?.raw?.icon === "circle" ? 3 : 6,
        pointStyle: (context) => context?.raw?.icon,
        pointHoverRadius: 6,
        borderDash: [0, 0],
      };
    });

    // Build Limit line
    const limitLine = {
      type: "line",
      label: "FEUM Intensity Limit",
      data: Object.entries(limitDetails)
        .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
        .map(([year, limit]) => ({
          x: +year,
          y: +Number(limit).toFixed(2),
        })),
      backgroundColor: "#E2C767",
      borderColor: "#E2C767",
      borderWidth: 3,
      lineTension: 0.05,
      pointRadius: 2,
      borderDash: [5, 5],
    };

    return {
      datasets: [limitLine, ...vesselLines],
      labels: complianceYears.map((y) => y),
      title: feum_JSON.chartTitle,
      subTitle: feum_JSON.chartSubTitle,
      scTitle: feum_JSON.scenarioSubtitle,
      comments: feum_JSON.comments,
    };
  };

  // 9. Build IMO-MTM chart data (simpler, no borrowing/banking)
  const buildMtmDatasets = () => {
    if (!mtm_JSON) return { datasets: [], labels: [] };

    const mtmDetails = mtm_JSON.mtm_details || [];
    const limitDetails = mtm_JSON.mtm_intensityLimit || {};
    const complianceYears = createArrayBetween(
      mtm_JSON.firstYear,
      mtm_JSON.lastYear
    );

    // Build Vessel lines (no borrowing or banking)
    const vesselLines = mtmDetails.map((entry) => {
      const { vesselName, colorHex, pointsByYear } = entry;
      const sortedYearData = Object.entries(pointsByYear)
        .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
        .map(([year, mtmElement]) => {
          const icon = determineIcon(mtmElement);
          return {
            x: +year,
            y: +Number(mtmElement.postHelpIntensity_gCo2eq_MJ || 0).toFixed(2),
            vesselName,
            postHelpIntensity_gCo2eq_MJ: mtmElement.postHelpIntensity_gCo2eq_MJ || 0,
            unaidedIntensity_gCo2eq_MJ: mtmElement.unaidedIntensity_gCo2eq_MJ || 0,
            externalCredits_gCO2eq_basis365: mtmElement.externalCredits_gCO2eq_basis365 || 0,
            icon,
          };
        });

      return {
        type: "line",
        label: vesselName,
        data: sortedYearData,
        backgroundColor: colorHex,
        borderColor: colorHex,
        borderWidth: 3,
        lineTension: 0.05,
        pointRadius: (context) =>
          context?.raw?.icon === "circle" ? 3 : 6,
        pointStyle: (context) => context?.raw?.icon,
        pointHoverRadius: 6,
        borderDash: [0, 0],
      };
    });

    // Build Limit line
    const limitLine = {
      type: "line",
      label: "IMO-MTM Intensity Limit",
      data: Object.entries(limitDetails)
        .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB))
        .map(([year, limit]) => ({
          x: +year,
          y: +Number(limit).toFixed(2),
        })),
      backgroundColor: "#E2C767",
      borderColor: "#E2C767",
      borderWidth: 3,
      lineTension: 0.05,
      pointRadius: 2,
      borderDash: [5, 5],
    };

    return {
      datasets: [limitLine, ...vesselLines],
      labels: complianceYears.map((y) => y),
      title: mtm_JSON.chartTitle,
      subTitle: mtm_JSON.chartSubTitle,
      scTitle: mtm_JSON.scenarioSubtitle,
      comments: mtm_JSON.comments,
    };
  };

  // 10. Build final chart object based on selection
  const {
    datasets,
    labels,
    title: chartTitle,
    subTitle: chartSubTitle,
    scTitle: scenarioSubTitle,
    comments: chartComments,
  } =
    selectedChart === "FEUM"
      ? buildFeumDatasets()
      : selectedChart === "IMO"
      ? buildMtmDatasets()
      : { datasets: [], labels: [] };

  // 11. “Notes & Sources” click logic
  const handleOpenInfo = () => {
    setTitle(chartTitle || "Chart Information");
    setComments(chartComments);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    chartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  return (
    <Grid
      ref={chartRef}
      container
      className={`px-4 pt-[21px] pb-[22.57px] h-full ${customClass}`}
    >
      {/* If no chart data at all, display a fallback */}
      {!hasFEUM && !hasMTM && (
        <Typography variant="h6">No chart data is available.</Typography>
      )}

      {/* If at least one chart is selected and available, show the chart */}
      {selectedChart !== "none" && datasets.length > 0 && (
        <>
          <Box className="flex justify-between items-start w-full mb-4">
            {/* Left side: chart title / subtitle */}
            <Box>
              <Typography
                variant="subtitle1"
                className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
              >
                {chartTitle}
              </Typography>
              {chartSubTitle && (
                <Typography
                  variant="subtitle2"
                  className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
                >
                  {chartSubTitle}
                </Typography>
              )}
              {scenarioSubTitle && (
                <Typography
                  variant="subtitle2"
                  className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
                >
                  {scenarioSubTitle}
                </Typography>
              )}
            </Box>

            {/* Right side: FEUM/IMO toggle + info icon */}
            <Box className="flex items-center space-x-2">
              <Button
                variant={selectedChart === "FEUM" ? "contained" : "outlined"}
                onClick={() => setSelectedChart("FEUM")}
                disabled={!hasFEUM}
              >
                FEUM
              </Button>
              <Button
                variant={selectedChart === "IMO" ? "contained" : "outlined"}
                onClick={() => setSelectedChart("IMO")}
                disabled={!hasMTM}
              >
                IMO-MTM
              </Button>

              {chartComments && show && (
                <Tooltip title="Notes and Sources" arrow>
                  <Button onClick={handleOpenInfo}>
                    <img src={open ? UPImg : BottomImg} alt="Information" />
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Box>

          <Divider className="w-full border border-solid border-mortar-grey my-4" />

          <Grid item xs={12} className="compliance-chart-container">
            <Line
              key={selectedChart} // ensures a fresh Chart.js instance on toggle
              data={{
                labels,
                datasets,
              }}
              options={optionsFEUM}
            />
          </Grid>

          {open && (
            <div ref={chartInfoRef}>
              <Divider className="w-full border border-solid border-mortar-grey my-4" />
              <div className="log-outmodal info">
                <div className="info-header">
                  <Grid className="flex justify-start items-start">
                    <Typography
                      variant="h3"
                      className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                    >
                      {title}
                    </Typography>
                  </Grid>
                  <Button onClick={handleClose}>
                    <img src={CloseImg} alt="close" />
                  </Button>
                </div>
                {comments && (
                  <div
                    className="text-base mt-[10px] info-text text-white"
                    dangerouslySetInnerHTML={{ __html: comments }}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </Grid>
  );
}
