import axios from "axios";
import axiosIns from "../libs/axios";
import { reactAppConfig } from "../config/react-app-config";

export const getAllFuelTypes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelTypes/all`,
  });
};

export const getAllFuels = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelFamilies/all`,
  });
};

export const getAllVesselTypes = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v1/FE/vesselFamilies/all`,
  });
};
export const getAllVesselTypesNew = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/vessels/vesselTypes/all`,
  });
};

export const routeAll = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/routes/all/DTO`,
  });
};

export const vesselTypesId = (vesselId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/vessels/vesselTypes/${vesselId}`,
  });
};

export const routeKpi = (routeId, speed) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/FE/routeKPI/${routeId}/${speed}`,
  });
};

export const compare = (data) => {
  return axiosIns({
    method: "GET",
    url: `/api/v1/FE/compare`,
  });
};

export const routePatterns = (routeId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/routes/${routeId}`,
  });
};

export const sendPdfByEmail = (caseId, email, file) => {
  console.log(file, caseId);
  return axiosIns({
    method: "POST",
    url: `/api/v2/FE/requestResultsWithPDF/${caseId}?email=${email}`,
    data: file,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const resetPass = (email) => {
  return axios.request({
    method: "POST",
    url: `${reactAppConfig.baseUrl}/api/v2/userManagement/request-password-reset?email=${email}`,
    headers: {
      accept: "application/json",
    },
  });
};

export const compareCii = (data) => {
  return axiosIns({
    method: "POST",
    url: `/api/v2/FE/compare`,
    data: data,
  });
};

export const vesselTypeImages = (vesselTypeId, type) => {
  return axiosIns({
    method: "GET",
    url: `api/v2/vessels/vesselTypeImages/${vesselTypeId}/${type}`,
    responseType: "arraybuffer",
  });
};

export const getAllFuelPrice = (scenarioId, routeId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelPricing/${scenarioId}/${routeId}`,
  });
};

export const getAllFuelNew = (scenarioId, routeId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelFamilies/all/LimitedByPricingAvailability/${scenarioId}/${routeId}`,
  });
};

export const getAllFuelPriceNormalize = (scenarioId, routeId) => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuels/fuelPricing/normalize/${scenarioId}/${routeId}`,
  });
};

// Fernando: Apparently not in use, Feb 12 2025
// export const getFuelPriceNew = (scenarioId, routeId, normalizeByLHV, data) => {
//   return axiosIns({
//     method: "POST",
//     url: `/api/v2/fuelPrices/${scenarioId}/${routeId}/${normalizeByLHV}`,
//     data: data,
//   });
// };

export const allPriceForecastScenario = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/fuelPrices/allScenarios`,
  });
};

export const allLegislativeScenario = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/legislation/allScenarios`,
  });
};
export const faqAll = () => {
  return axiosIns({
    method: "GET",
    url: `/api/v2/FAQ/all`,
  });
};

export const allVesrionInfo = () => {
  return axiosIns({
    method: "GET",
    url: `/actuator/info`,
  });
};

export const getUserInfo = () => {
  return axiosIns({
    method: "GET",
    url: `/api/auth/getUserInfo`,
  })
}
