import { useEffect, useContext, useRef, useState } from "react";
import { chartContext } from "../../common/ChartContex";
import pattern from "patternomaly";
import {
  Box,
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// TODO: the legends in NPV of Expense by Fuel Type and Vessel [MUSD]
// should include the fuel type, because this is not visible for the very short columns

import { customTable } from "../../common/componentStyle";
import { Line, Bar } from "react-chartjs-2";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

import { createArrayBetween, patternsObj } from "../../common/formatter";

export default function Financial({
  customClass,
  textBlack,
  optionsCCO,
  fuelExpenseOptions,
  show,
  fonSize,
}) {
  const chartData = useContext(chartContext);

  useEffect(() => { }, [chartData]);

  const financialKPI = chartData?.kpisSpecification;

  const financial = financialKPI?.perVesselKPIs;

  const cumulativeData = chartData?.cashFlowChartSpecification;

  const cumulativeYear = createArrayBetween(
    cumulativeData?.firstYear,
    cumulativeData?.lastYear
  );

  const cumulativeCashFlowData = {
    labels: cumulativeYear?.map((i) => i),
    datasets: cumulativeData?.perVesselLines?.map((data, index) => ({
      label: data?.vesselName,
      data: data?.points
        ?.filter((e) => e.year >= cumulativeData.firstYear)
        .map((d) => (Math.round(d.value * 100) / 100).toFixed(2)),
      fill: false,
      backgroundColor: data?.colorHex,
      borderColor: data?.colorHex,
      borderDash: [0, 0],
    })),
  };

  const [keyFuelExpense, setKeyFuelExpense] = useState([]);
  const [valueFuelExpense, setValueFuelExpense] = useState([]);

  useEffect(() => {
    if (chartData) {
      const fuelExpense_new = chartData.fuelExpense.fuelExpense_v2;

      const chartLabels = Object.keys(fuelExpense_new);

      const allFuelTypes = [
        ...new Set(
          chartLabels
            ?.map((shipName) => Object.keys(fuelExpense_new[shipName]))
            .flat()
        ),
      ];

      const datasets = allFuelTypes.map((fuelType) => {
        return {
          label: fuelType,
          data: chartLabels?.map((shipName) => {
            return fuelExpense_new[shipName][fuelType]?.cost || 0;
          }),
          backgroundColor: chartLabels?.map((shipName) => {
            const shipData = fuelExpense_new[shipName][fuelType];
            if (!shipData) {
              return null;
            }
            if (shipData?.pattern === "solid") {
              return shipData.color;
            } else if (shipData?.pattern) {
              return pattern.draw(patternsObj[shipData.pattern], shipData.color);
            }
            return null;
          }),
        };
      });

      datasets.sort((a, b) => {
        const costA = a.data[0];
        const costB = b.data[0];
        return costB - costA;
      });

      const filteredDatasets = datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor.filter(
          (color) => color !== null
        ),
      }));

      const filteredDatasetsArray = filteredDatasets.filter(item => {
        return !item.data.every(value => value === 0);
      });

      setKeyFuelExpense(chartLabels);
      setValueFuelExpense(filteredDatasetsArray);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [title1, setTitle1] = useState("Chart Information");
  const [title2, setTitle2] = useState("Chart Information");
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);

  const financialTableRef = useRef(null);
  const fuelExpenseRef = useRef(null);
  const financialTable = useRef(null);
  const cumulativeChartRef = useRef(null);
  const fuelExpenseChartRef = useRef(null);

  const handleFinancialChart = (title) => {
    setTitle(title);
    setData(financialKPI?.comments);
    setOpen(!open);
  };

  const handleCloseFinancialInfo = () => {
    setOpen(false);
    financialTable?.current?.scrollIntoView({ behavior: "auto" });
  };

  const handleCloseCumulativeInfo = () => {
    setOpen1(false);
    cumulativeChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  const handleCloseFuelExpenseInfo = () => {
    setOpen2(false);
    fuelExpenseChartRef?.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    if (open) {
      financialTableRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  useEffect(() => {
    if (open1) {
      cumulativeRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open1]);

  useEffect(() => {
    if (open2) {
      fuelExpenseRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open2]);

  const handleCumulativeChart = (title) => {
    setTitle1(title);
    setData1(cumulativeData.comments);
    setOpen1(!open1);
  };

  const handleFinancialFuelChart = (title) => {
    setTitle2(title);
    setData2(chartData.fuelExpense.comments);
    setOpen2(!open2);
  };

  const financialWithoutVesselName = Object.keys(financial).reduce(
    (result, vesselName) => {
      const { vesselName: omitVesselName, ...rest } = financial[vesselName];
      result[vesselName] = rest;
      return result;
    },
    {}
  );

  const cumulativeRef = useRef(null);

  return (

    <>
      <Grid item xs={12} ref={financialTable}>
        <Box
          className={`h-full w-full p pl-2 pt-6 pr-4 ps-4 pb-6 rounded-2xl bg-new-text ${customClass}`}
        >
          <Box className="flex justify-between">
            <Box>
              <Typography
                variant="subtitle1"
                className={`flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
              >
                {financialKPI?.chartTitle}
              </Typography>
              <Typography
                variant="subtitle2"
                className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
              >
                {financialKPI?.chartSubTitle}
              </Typography>

              <Typography
                variant="subtitle2"
                className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
              >
                {financialKPI?.scenarioSubtitle}
              </Typography>
            </Box>

            {financialKPI?.comments && show && (
              <Box>
                <Tooltip title="Notes and Sources" arrow>
                  <Button
                    type="submit"
                    onClick={() =>
                      handleFinancialChart(financialKPI?.chartTitle)
                    }
                  >
                    <img src={open ? UPImg : BottomImg} alt="Information" />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
          <Divider className="border border-solid border-mortar-grey my-4" />

          <Table aria-label="simple table" sx={customTable}>
            <>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {chartData &&
                    Object.keys(financial).map((e, index) => (
                      <TableCell
                        className={`font-medium text-[15px] leading-[15px] text-title px-[10px] ${textBlack}`}
                        key={index}
                      >
                        {e}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(
                  financialWithoutVesselName[
                  Object.keys(financialWithoutVesselName)[0]
                  ]
                ).map((property) => (
                  <TableRow
                    key={property}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component=""
                      scope="row"
                      className={`font-medium text-[14px] leading-[13px]  text-title w-28 text-left ${textBlack}`}
                    >
                      {property}
                    </TableCell>
                    {chartData &&
                      Object.keys(financialWithoutVesselName).map(
                        (vesselName) => (
                          <TableCell
                            key={vesselName}
                            className={`font-bold text-[13px] leading-[13px] text-updated-sub-text border border-solid border-midnight-navy text-center ${textBlack} ${fonSize}`}
                          >
                            {`${financialWithoutVesselName[vesselName][property]}`}
                          </TableCell>
                        )
                      )}
                  </TableRow>
                ))}
              </TableBody>
            </>
          </Table>

          {open && (
            <div
              ref={financialTableRef}
              id="financialTableInfo"
              className="pt-[2em]"
            >
              <Divider className="border border-solid border-mortar-grey my-4" />
              <div className="log-outmodal info">
                <div className="info-header">
                  <Grid className="flex justify-start items-start">
                    <Typography
                      variant="h3"
                      className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                    >
                      {title}
                    </Typography>
                  </Grid>
                  <Button onClick={handleCloseFinancialInfo}>
                    <img src={CloseImg} alt="close" />
                  </Button>
                </div>
                {data && (
                  <div
                    className="text-base mt-[10px] info-text text-updated-sub-text"
                    dangerouslySetInnerHTML={{ __html: data }}
                  />
                )}
              </div>
            </div>
          )}
        </Box>
      </Grid>

      <Grid
        id="cumulative"
        container
        ref={cumulativeChartRef}
        className={`mt-6 px-4 pt-7 pb-[24.57px] rounded-2xl bg-new-text ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {cumulativeData?.chartTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {cumulativeData?.chartSubTitle}
            </Typography>

            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {cumulativeData?.scenarioSubtitle}
            </Typography>
          </Box>

          {cumulativeData?.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  id="cumulativeBtn"
                  type="submit"
                  onClick={() => {
                    handleCumulativeChart(cumulativeData?.chartTitle);
                  }}
                >
                  <img src={open1 ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4" />
        {chartData ? (
          <Box className="w-full h-[450px]">
            <Line data={cumulativeCashFlowData} options={optionsCCO} />
          </Box>
        ) : (
          "feiching"
        )}

        {open1 && (
          <div ref={cumulativeRef} className="pt-[2em]">
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title1}
                  </Typography>
                </Grid>
                <Button onClick={handleCloseCumulativeInfo}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data1 && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data1 }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>

      <Grid
        container
        ref={fuelExpenseChartRef}
        className={`mt-6 px-4 pt-7 pb-[24.57px] rounded-2xl bg-new-text ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {chartData.fuelExpense?.chartTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {chartData?.fuelExpense?.chartSubTitle}
            </Typography>

            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {chartData?.fuelExpense?.scenarioSubtitle}
            </Typography>
          </Box>

          {chartData.fuelExpense.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  type="submit"
                  onClick={() => {
                    handleFinancialFuelChart(
                      chartData.fuelExpense?.chartTitle
                    );
                  }}
                >
                  <img src={open2 ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4" />
        <Bar
          className="h-full"
          style={{ height: "30em" }}
          data={{ labels: keyFuelExpense, datasets: valueFuelExpense }}
          options={fuelExpenseOptions}
        />
        {open2 && (
          <div ref={fuelExpenseRef} className="pt-[2em]">
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header w-full">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title2}
                  </Typography>
                </Grid>
                <Button onClick={handleCloseFuelExpenseInfo}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data2 && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data2 }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
    </>

  );
}
