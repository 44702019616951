import { Divider, Grid, Typography, Box, Button } from "@mui/material";
import { Bar } from "react-chartjs-2";
import pattern from "patternomaly";

import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { chartContext } from "../../common/ChartContex";

import { formatNumber, patternsObj } from "../../common/formatter";
import Tooltip from "@mui/material/Tooltip";
import BottomImg from "../../assets/images/square-images/infodown.svg";
import UPImg from "../../assets/images/square-images/infoup.svg";
import CloseImg from "../../assets/images/square-images/close.svg";

Chart.register(ChartDataLabels);
export default function Emissions({
  customClass,
  optionsEmmission,
  optionsEmmission_gwp,
  textBlack,
  show,
}) {
  const chartData = useContext(chartContext);

  const [key, setKey] = useState([]);
  const [value, setValue] = useState([]);

  const [key1, setKey1] = useState([]);
  const [value1, setValue1] = useState([]);

  const emmissionChart = chartData?.totalPollutionChartSpecification_gwp100;

  const emmissionChart_gwp = chartData?.totalPollutionChartSpecification_gwp20;

  useEffect(() => {
    if (chartData) {
      // emission gwp20

      const emissionTotals_gwp = emmissionChart_gwp?.emissionTotals;

      const chartLabelsEmission_gwp = Object.keys(emissionTotals_gwp);

      if (chartLabelsEmission_gwp.length === 0) { return }

      const pollutants_gwp = [
        ...new Set(
          emissionTotals_gwp[chartLabelsEmission_gwp[0]].map(
            (item) => item.pollutant
          )
        ),
      ];

      const datasetsEmission_gwp = pollutants_gwp.map((pollutant, index) => {
        return {
          label: pollutant,
          data: chartLabelsEmission_gwp.map((shipName) => {
            const shipData = emissionTotals_gwp[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? shipData.gwp : 0;
          }),

          backgroundColor: chartLabelsEmission_gwp.map((shipName) => {
            const shipData = emissionTotals_gwp[shipName].find(
              (item) => item.pollutant === pollutant
            );
            if (shipData?.pattern === "solid") {
              return shipData.color;
            } else if (shipData?.pattern) {
              return pattern.draw(patternsObj[shipData.pattern], shipData.color);
            }
          }),

          qty: chartLabelsEmission_gwp.map((shipName) => {
            const shipData = emissionTotals_gwp[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? formatNumber(Math.floor(shipData.qtyMt)) : 0;
          }),
          gwp: chartLabelsEmission_gwp.map((shipName) => {
            const shipData = emissionTotals_gwp[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? formatNumber(Math.floor(shipData.gwp)) : 0;
          }),
        };
      });

      datasetsEmission_gwp.sort((a, b) => {
        const sumA = a.data.reduce((acc, val) => acc + val, 0);
        const sumB = b.data.reduce((acc, val) => acc + val, 0);
        return sumB - sumA;
      });

      setKey1(chartLabelsEmission_gwp);
      setValue1(datasetsEmission_gwp);

      // emission gwp100
      const emissionTotals = emmissionChart?.emissionTotals;

      const chartLabelsEmission = Object.keys(emissionTotals);
      const pollutants = [
        ...new Set(
          emissionTotals[chartLabelsEmission[0]].map((item) => item.pollutant)
        ),
      ];

      const datasetsEmission = pollutants.map((pollutant, index) => {
        return {
          label: pollutant,
          data: chartLabelsEmission.map((shipName) => {
            const shipData = emissionTotals[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? shipData.gwp : 0;
          }),
          backgroundColor: chartLabelsEmission.map((shipName) => {
            const shipData = emissionTotals_gwp[shipName].find(
              (item) => item.pollutant === pollutant
            );
            if (shipData?.pattern === "solid") {
              return shipData.color;
            } else if (shipData?.pattern) {
              return pattern.draw(patternsObj[shipData.pattern], shipData.color);
            }
          }),
          qty: chartLabelsEmission.map((shipName) => {
            const shipData = emissionTotals[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? formatNumber(Math.floor(shipData.qtyMt)) : 0;
          }),
          gwp: chartLabelsEmission_gwp.map((shipName) => {
            const shipData = emissionTotals[shipName].find(
              (item) => item.pollutant === pollutant
            );
            return shipData ? formatNumber(Math.floor(shipData.gwp)) : 0;
          }),
        };
      });

      datasetsEmission.sort((a, b) => {
        const sumA = a.data.reduce((acc, val) => acc + val, 0);
        const sumB = b.data.reduce((acc, val) => acc + val, 0);
        return sumB - sumA;
      });

      setKey(chartLabelsEmission);
      setValue(datasetsEmission);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Chart Information");
  const [data, setData] = useState(null);

  const [open1, setOpen1] = useState(false);
  const [title1, setTitle1] = useState("Chart Information");
  const [data1, setData1] = useState(null);

  const handleEmmissionChart = (title) => {
    setTitle(title);
    setData(emmissionChart.comments);
    setOpen(!open);
  };

  const handleEmmissionGwpChart = (title) => {
    setTitle1(title);
    setData1(emmissionChart_gwp.comments);
    setOpen1(!open1);
  };

  const handleClose = () => {
    setOpen(false);
    emissionChart1?.current?.scrollIntoView({ behavior: "auto" });
  };

  const handleClose1 = () => {
    setOpen1(false);
    emissionChart2?.current?.scrollIntoView({ behavior: "auto" });
  };

  const emissionChart1 = useRef(null);
  const emissionChart1Info = useRef(null);
  const emissionChart2 = useRef(null);
  const emissionChart2Info = useRef(null);

  useEffect(() => {
    if (open) {
      emissionChart1Info.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open]);

  useEffect(() => {
    if (open1) {
      emissionChart2Info.current.scrollIntoView({ behavior: "auto" });
    }
  }, [open1]);

  return (
    <>
      <Grid
        ref={emissionChart1}
        container
        className={` w-full px-4 pt-[21px] pb-6 rounded-2xl bg-new-text ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {emmissionChart?.chartTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {emmissionChart?.chartSubTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {emmissionChart?.scenarioSubtitle}
            </Typography>
          </Box>

          {emmissionChart?.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  type="submit"
                  onClick={() => {
                    handleEmmissionChart(emmissionChart?.chartTitle);
                  }}
                >
                  <img src={open ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4" />
        <Grid item xs={12}>
          <Bar
            className=""
            style={{ height: "30em" }}
            data={{ labels: key, datasets: value }}
            options={optionsEmmission}
          />
        </Grid>
        {open && (
          <div ref={emissionChart1Info}>
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title}
                  </Typography>
                </Grid>
                <Button onClick={handleClose}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
      <Grid
        ref={emissionChart2}
        container
        className={`mt-[30px] w-full px-4 pt-[21px] pb-6 rounded-2xl bg-new-text ${customClass}`}
      >
        <Box className="flex justify-between w-full">
          <Box>
            <Typography
              variant="subtitle1"
              className={`w-full flex justify-start font-medium text-xl leading-[25px] text-updated-primary-text ${textBlack}`}
            >
              {emmissionChart_gwp?.chartTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {emmissionChart_gwp?.chartSubTitle}
            </Typography>
            <Typography
              variant="subtitle2"
              className={`mt-[6px] w-full flex justify-start font-medium text-[15.0877px] leading-[19px] text-updated-sub-text ${textBlack}`}
            >
              {emmissionChart_gwp?.scenarioSubtitle}
            </Typography>
          </Box>

          {emmissionChart_gwp?.comments && show && (
            <Box>
              <Tooltip title="Notes and Sources" arrow>
                <Button
                  type="submit"
                  onClick={() => {
                    handleEmmissionGwpChart(emmissionChart_gwp?.chartTitle);
                  }}
                >
                  <img src={open1 ? UPImg : BottomImg} alt="Information" />
                </Button>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Divider className="w-full border border-solid border-mortar-grey my-4" />
        <Grid item xs={12}>
          <Bar
            className=""
            style={{ height: "30em" }}
            data={{ labels: key1, datasets: value1 }}
            options={optionsEmmission_gwp}
          />
        </Grid>
        {open1 && (
          <div ref={emissionChart2Info}>
            <Divider className="w-full border border-solid border-mortar-grey my-4" />
            <div className="log-outmodal info">
              <div className="info-header">
                <Grid className="flex justify-start items-start ">
                  <Typography
                    variant="h3"
                    className="ml-0 p-0 normal-case text-xl font-medium text-updated-primary-text"
                  >
                    {title1}
                  </Typography>
                </Grid>
                <Button onClick={handleClose1}>
                  <img src={CloseImg} alt="close" />
                </Button>
              </div>
              {data1 && (
                <div
                  className="text-base mt-[10px] info-text text-white"
                  dangerouslySetInnerHTML={{ __html: data1 }}
                />
              )}
            </div>
          </div>
        )}
      </Grid>
    </>
  );
}
